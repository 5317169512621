import Main from "../layout/Main";
import StudentLayout from "../layout/student/StudentLayout";
import StudentLayoutNobar from "../layout/student/StudentLayoutNobar";

//独立 liberty
const LiveScript = () =>
    import ("../views/student/LiveScript.vue")
const OtherLiveRoom = () =>
    import ("../views/student/OtherLiveRoom.vue")
const LiveRoom = () =>
    import ("../views/student/LiveRoom.vue")
const LiveDebug = () =>
    import ("../views/student/LiveDebug.vue")
const liveReplay = () =>
    import ("../views/student/liveReplay.vue")

//查看其他学生直播
const OtherStuLive = () =>
    import ("../views/student/SeeStudentLive.vue")

//直播选品
const LiveSelection = () =>
    import ("../views/student/liveSelection/LiveSelection.vue");
const LiveSelectionDetail = () =>
    import ("../views/student/liveSelection/liveSelectionDetail.vue");

//直播营销
const DiscountCoupon = () =>
    import ("../views/student/liveMarketing/discountCoupon/DiscountCoupon.vue");
const addCoupon = () =>
    import ("../views/student/liveMarketing/discountCoupon/addCoupon.vue");
const LuckyBag = () =>
    import ("../views/student/liveMarketing/luckyBag/LuckyBag.vue");
const LuckyBagCreate = () =>
    import ("../views/student/liveMarketing/luckyBag/CreateAndEditBag.vue");
const LuckyBagEdit = () =>
    import ("../views/student/liveMarketing/luckyBag/CreateAndEditBag.vue");

//直播推广
const AdLaunch = () =>
    import ("../views/student/liveExpand/adLaunch/AdLaunch.vue");
const AdCreate = () =>
    import ("../views/student/liveExpand/adLaunch/AdCreate.vue");
const MarketData = () =>
    import ("../views/student/liveExpand/marketData/MarketData.vue");

//直播间管理
const LiveRoomManage = () =>
    import ("../views/student/liveRoomManage/LiveRoomManage.vue");
const CreateLiveRoom = () =>
    import ("../views/student/liveRoomManage/createLiveRoom.vue");

//复盘数据
const replayData = () =>
    import ("../views/student/replayData.vue");


const liberty = [{
    path: "/liveRoom",
    name: "LiveRoom",
    component: LiveRoom,
    meta: {
        role: 5,
        title: "直播间"
    },
}, {
    path: "/otherLiveRoomList",
    name: "otherLiveRoomList",
    component: OtherLiveRoom,
    meta: {
        role: 5,
        title: "其他直播间"
    },
}, {
    path: "/otherStuLiveRoom",
    name: "otherStuLiveRoom",
    component: OtherStuLive,
    meta: {
        role: 5,
        title: "他人直播大屏"
    },
}, {
    path: "/replayData",
    name: "replayData",
    component: replayData,
    meta: {
        role: 5,
        title: "复盘数据"
    },
}, {
    path: "/liveDebug",
    name: "LiveDebug",
    component: LiveDebug,
    meta: {
        role: 5,
        title: "试播调试"
    },
}, {
    path: "/liveReplay",
    name: "liveReplay",
    component: liveReplay,
    meta: {
        role: 5,
        title: "直播复盘"
    },
},

]

const routes = [{
    path: "/student",
    redirect: "/liveScheme",
    name: "Student",
    component: StudentLayout,
    children: [{
        path: "/liveScheme",
        redirect: "/liveSelection",
        name: "LiveScheme",
        components: {
            default: Main,
            embed: LiveScript
        },
        meta: {
            title: "直播方案",
            icon: "&#xe634;",
            role: 5,
            tree: true
        },
        children: [{
            path: "/liveSelection",
            redirect: "/liveSelection/selectGoods",
            name: "LiveSelection",
            component: Main,
            meta: {
                title: "直播选品",
                icon: "&#xe634;",
                role: 5,
                embed: true,
                parentName: ["LiveScheme"]
            },
            children: [{
                path: "/liveSelection/selectGoods",
                name: "SelectGoods",
                component: LiveSelection,
                meta: {
                    title: "直播选品",
                    role: 5,
                    parentName: ["LiveSelection"]
                },
            }, /*{
                path: "/live/selection/detail",
                name: "liveSelectionGoods",
                component: LiveSelectionDetail,
                meta: {
                    title: "商品数据",
                    role: 5,
                    embed: false,
                    hidden: true,
                    parentName: ["LiveSelection"]
                },
            }*/],
        }, {
            path: "/liveMarketing",
            redirect: "/liveMarketing/discountCoupon",
            name: "LiveMarketing",
            component: Main,
            meta: {
                title: "直播营销",
                icon: "&#xe607;",
                role: 5,
                embed: true,
                parentName: ["LiveScheme"]
            },
            children: [{
                path: "/liveMarketing/discountCoupon",
                name: "DiscountCoupon",
                component: DiscountCoupon,
                meta: {
                    title: "优惠券",
                    role: 5,
                    parentName: ["LiveMarketing"],
                },
            }, {
                path: "/liveMarketing/addCoupon",
                name: "addCoupon",
                component: addCoupon,
                meta: {
                    title: "创建优惠券",
                    role: 5,
                    level: 2,
                    hidden: true,
                    parentName: ["LiveMarketing"],
                },
            }, {
                path: "/liveMarketing/luckyBag",
                name: "LuckyBag",
                component: LuckyBag,
                meta: {
                    title: "福袋",
                    role: 5,
                    parentName: ["LiveMarketing"],
                },
            }, {
                path: "/liveMarketing/luckyBagCreate",
                name: "luckyBagCreate",
                component: LuckyBagCreate,
                meta: {
                    title: "创建福袋",
                    role: 5,
                    level: 2,
                    hidden: true,
                    parentName: ["LiveMarketing"],
                },
            }, {
                path: "/liveMarketing/LuckyBagEdit",
                name: "LuckyBagEdit",
                component: LuckyBagEdit,
                meta: {
                    title: "编辑福袋",
                    role: 5,
                    level: 2,
                    hidden: true,
                    parentName: ["LiveMarketing"],
                }
            }],
        }, {
            path: "/liveExpand",
            redirect: "/liveExpand/adLaunch",
            name: "LiveExpand",
            component: Main,
            meta: {
                title: "直播推广",
                icon: "&#xe6be;",
                role: 5,
                parentName: ["LiveScheme"]
            },
            children: [{
                path: "/liveExpand/adLaunch",
                name: "AdLaunch",
                component: AdLaunch,
                meta: {
                    title: "广告投放",
                    role: 5,
                    parentName: ["LiveExpand"],
                },
            }, {
                path: "/liveExpand/adCreate",
                name: "AdCreate",
                component: AdCreate,
                meta: {
                    title: "新建计划",
                    role: 5,
                    parentName: ["LiveExpand", 'AdLaunch'],
                    hidden: 'true'
                },
            }, {
                path: "/liveExpand/marketData",
                name: "MarketData",
                component: MarketData,
                meta: {
                    title: "市场数据",
                    role: 5,
                    parentName: ["LiveExpand"],
                },
            }],
        }]
    }, {
        path: "/liveWarmup",
        redirect: "/liveWarmup/index",
        name: "LiveWarmup",
        components: {
            default: Main,
            embed: LiveScript
        },
        meta: {
            title: "直播预热",
            icon: "&#xe66a;",
            role: 5,
            embed: true
        },
        children: [{
            path: "/liveWarmup/index",
            name: "LiveWarmupIndex",
            component: () => import("../views/student/liveWarmup/LiveWarmup.vue"),
            meta: {
                title: "短视频管理",
                role: 5,
                parentName: ["LiveWarmup"],
            }
        }, {
            path: "/liveWarmup/release",
            name: "LiveWarmupIndex",
            component: () => import("../views/student/liveWarmup/ReleaseVideo.vue"),
            meta: {
                title: "发布视频",
                role: 5,
                level: 2,
                hidden: true,
                parentName: ["LiveWarmup"],
            }
        }]
    }, {
        path: "/liveRoomManage",
        redirect: "/liveRoomManage/index",
        name: "LiveRoomManage",
        component: Main,
        meta: {
            title: "直播间管理",
            icon: "&#xe637;",
            role: 5,
        },
        children: [{
            path: "/liveRoomManage/index",
            name: "LiveRoomManageIndex",
            component: LiveRoomManage,
            meta: {
                title: "直播间管理",
                role: 5,
                parentName: ["LiveRoomManage"],
            },
        }, {
            path: "/liveRoomManage/createLive",
            name: "LiveRoomManageIndex",
            component: CreateLiveRoom,
            meta: {
                title: "创建直播间",
                role: 5,
                parentName: ["LiveRoomManage"],
            },
        }],
    }],
},{
    path: '/live/selection/detail',
    name: 'goodsdata',
    component: StudentLayoutNobar,
    children: [
        {
            path: '',
            name: "goodsdatapanel",
            component: LiveSelectionDetail,
            meta: {
                title: "商品数据",
                role: 5
            }
        }
    ]
}, ...liberty];

export default routes;